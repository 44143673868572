import { gettext } from "./i18n.js"

export function SealedFloatingLink({ name, position, modifierClass }) {
  return (
    <button
      type="button"
      className={`floating-link is-sealed ${modifierClass || ""}`}
      style={{
        left: `${position.x}%`,
        top: `${position.y}%`,
      }}
    >
      <div className="floating-link__not-challenged">
        {gettext("You don't have enough experience yet to enter the Zeppelin")}
      </div>
      <div className="floating-link__description">
        <h3>{name}</h3>
      </div>
    </button>
  )
}
