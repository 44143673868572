/*
Static collision objects
========================

- Collision objects must be convex polygons
- Nodes must be specified in clockwise order
- The visible area's x and y coordinate range is [0, 1000]
- When negating overlap, objects are pushed in the direction of the nearest
  collision objects' edge. To avoid objects being pushed off-screen e.g. by
  buildings, ensure that buildings' collision edges end "far" outside the
  [0, 1000] range. (This is the reason for the 1e5 values you're seeing.)

*/
export function createStaticCollisionObjects(system, district) {
  addCollisionBorders(system)

  if (district.district === "skatepark") {
    skateparkDistrictNack(system)
  } else if (
    district.district === "downtown" ||
    district.district === "swissmoneyweek"
  ) {
    downtownDistrictNack(system)
  } else if (district.district === "entertainment") {
    entertainmentDistrictNack(system)
  } else if (district.district === "shoptown") {
    shoptownDistrictNack(system)
  } else if (district.district === "farmtown") {
    farmtownDistrictNack(system)
  } else if (district.district === "parktown") {
    parktownDistrictNack(system)
  } else if (district.district === "challenge") {
    challengeDistrictNack(system)
  }
}

function createPolygon(system, x, y, coords, ..._args) {
  system.createPolygon(
    { x, y },
    coords.map(([x, y]) => ({ x, y })),
    { isStatic: true },
    // ...args,
  )
}

function createCircle(system, x, y, radius) {
  system.createCircle({ x, y }, radius, { isStatic: true })
}

function addCollisionBorders(system) {
  const pad = 20
  // top
  createPolygon(system, 500, 0, [
    [-2500, pad],
    [-2500, -1e5],
    [-1e5, 2500],
    [2500, pad],
  ])
  // right
  createPolygon(system, 1000, 2500, [
    [-pad, -2500],
    [1e5, -2500],
    [1e5, 2500],
    [-pad, 2500],
  ])
  // bottom
  createPolygon(system, 2500, 1000, [
    [2500, -pad],
    [2500, 1e5],
    [-2500, 1e5],
    [-2500, -pad],
  ])
  // left
  createPolygon(system, 0, 2500, [
    [pad, 2500],
    [-1e5, 2500],
    [-1e5, -2500],
    [pad, -2500],
  ])
}

function createLamp(system, x, y) {
  createPolygon(system, x, y, [
    [-15, -200],
    [20, -170],
    [0, 100],
  ])
}

function createSmallLamp(system, x, y) {
  createPolygon(system, x, y, [
    [0, -15],
    [20, 0],
    [15, 90],
  ])
}

function createSmallTriangle(system, x, y) {
  createPolygon(system, x, y, [
    [0, 0],
    [15, 10],
    [3, 15],
  ])
}

function createFirTree(system, x, y) {
  createPolygon(system, x, y, [
    [0, 0],
    [25, 130],
    [-25, 130],
  ])
}

function createSmallSquare(system, x, y) {
  createPolygon(system, x, y, [
    [0, 0],
    [15, 10],
    [15, 60],
    [0, 50],
  ])
}

function createSmallSquare2(system, x, y) {
  createPolygon(system, x, y, [
    [0, 0],
    [15, -35],
    [15, 0],
    [0, 35],
  ])
}

function skateparkDistrictNack(system) {
  createLamp(system, 450, 630)
  createLamp(system, 760, 680)
  createLamp(system, 830, 240)
  createLamp(system, 260, 90)
  createSmallTriangle(system, 350, 155)
  createSmallTriangle(system, 595, 505)
  createSmallSquare(system, 240, 350)
  createSmallSquare2(system, 270, 330)

  // Ramp
  createPolygon(system, 0, 1000, [
    [-1e5, -640],
    [0, -640],
    [580, 0],
    [660, 1e5],
  ])

  // kiosk
  createPolygon(system, 0, 0, [
    [463, -1e5],
    [700, -1e5],
    [680, 80],
    [620, 180],
    [580, 190],
    [470, 110],
  ])

  // right top corner
  createPolygon(system, 0, 0, [
    [-1e5, -1e5],
    [1e5, 237],
    [900, 250],
    [800, 192],
    [699, 143],
  ])

  // right bottom corner
  createPolygon(system, 90, 0, [
    [500, 2000],
    [1000, 500],
    [1000, 1000],
  ])

  // toi toi
  createPolygon(system, 0, 80, [
    [661, 212],
    [735, 258],
    [727, 290],
    [651, 232],
  ])

  // tree
  createPolygon(system, 0, 115, [
    [342, 274],
    [367, 302],
    [342, 342],
    [328, 311],
  ])

  // left tree
  createPolygon(system, 0, 100, [
    [60, 90],
    [97, 245],
    [61, 256],
    [-20, 211],
  ])

  // behind halfpipe
  createPolygon(system, 0, 0, [
    [0, -500],
    [118, 0],
    [-20, 300],
    [-500, -200],
  ])
}

function downtownDistrictNack(system) {
  createSmallTriangle(system, 45, 375)
  createSmallTriangle(system, 30, 425)
  createSmallTriangle(system, 520, 785)
  createSmallTriangle(system, 845, 790)
  createSmallLamp(system, 75, 825)
  createSmallLamp(system, 495, 435)
  // Dark blue building, top left
  createPolygon(system, 0, 0, [
    [500, -1e5],
    [450, 0],
    [280, 370],
    [0, 240],
    [-1e5, -1e5],
  ])
  // Chart Banner
  createPolygon(system, 0, 0, [
    [500, -1e5],
    [800, 0],
    [800, 400],
    [0, -50],
    [-1e5, -1e5],
  ])
  // Blue building, bottom left
  createPolygon(system, 20, 1000, [
    [0, -500],
    [40, -450],
    [40, -20],
    [-1e5, -20],
  ])
  // Red building
  createPolygon(system, 1000, 1000, [
    [-60, 0],
    [-60, -800],
    [0, -900],
    [1e5, 0],
  ])
  // Light blue building
  createPolygon(system, 550, 0, [
    [350, 680],
    [0, 500],
    [0, 0],
    [1e5, -1e5],
  ])
  // bull
  createPolygon(system, 590, 710, [
    [0, 50],
    [0, 100],
    [75, 50],
    [75, 0],
  ])
  // cycle rack
  createPolygon(system, 180, 550, [
    [0, 0],
    [-30, 50],
    [0, 70],
    [30, 20],
  ])
  // pastel green bottom center roof
  createPolygon(system, 400, 980, [
    [0, -100],
    [-100, 100],
    [0, 200],
    [150, -25],
  ])
}

function entertainmentDistrictNack(system) {
  createSmallTriangle(system, 540, 405)
  createSmallTriangle(system, 425, 460)
  createSmallTriangle(system, 430, 550)
  createSmallTriangle(system, 375, 810)
  createSmallTriangle(system, 895, 805)

  // cinema
  createPolygon(system, 1000, 0, [
    [-200, -1000],
    [-200, 420],
    [0, 620],
    [1000, 650],
  ])
  // palace
  createPolygon(system, 0, -50, [
    [-1e5, 1000],
    [20, 1000],
    [280, 450],
    [320, 0],
    [300, -1e5],
  ])
  // guitar building
  createPolygon(system, 320, 0, [
    [330, -1e5],
    [330, 150],
    [200, 400],
    [20, 270],
    [-50, 0],
  ])

  // casino
  createPolygon(system, 0, 250, [
    [754, 351],
    [800, 524],
    [815, 1000],
    [553, 1000],
    [553, 640],
    [630, 414],
  ])
  //casino 2
  createPolygon(system, 0, 290, [
    [498, 492],
    [735, 650],
    [406, 700],
  ])
  //pink house left bottom
  createPolygon(system, -350, 260, [
    [400, 432],
    [635, 610],
    [606, 1700],
    [206, 1200],
  ])
  // pink house on the right bottom
  createPolygon(system, 0, 0, [
    [2000, -1400],
    [2000, 1600],
    [900, 1600],
    [900, 900],
  ])
  // lamp in the middle
  createPolygon(system, 0, 0, [
    [665, 440],
    [678, 455],
    [678, 530],
  ])
}

function createTree(system, x, y) {
  createPolygon(system, x, y, [
    [0, -80],
    [55, 0],
    [0, 100],
    [-50, 0],
  ])
}

function shoptownDistrictNack(system) {
  createTree(system, 600, 520)
  createTree(system, 840, 800)
  createSmallTriangle(system, 165, 930)
  createSmallTriangle(system, 310, 270)
  createSmallTriangle(system, 355, 230)
  createSmallTriangle(system, 415, 100)
  createSmallTriangle(system, 690, 465)
  createSmallTriangle(system, 810, 655)
  createSmallTriangle(system, 770, 745)
  createSmallTriangle(system, 775, 900)
  createSmallTriangle(system, 490, 915)
  createSmallTriangle(system, 950, 910)
  createSmallLamp(system, 450, 240)
  createSmallLamp(system, 940, 120)
  createSmallLamp(system, 630, 840)

  // Garage
  createPolygon(system, 0, 1000, [
    [-1e5, 0],
    [0, -430],
    [110, -350],
    [0, -80],
  ])
  // Mint green building to the left
  createPolygon(system, 0, 0, [
    [-1e5, -1e5],
    [390, 0],
    [170, 540],
    [0, 420],
  ])
  // Blue building at the top
  createPolygon(system, 520, 0, [
    [0, -1e5],
    [280, 0],
    [125, 330],
    [0, 280],
  ])
  // Burger building
  createPolygon(system, 1000, 1000, [
    [1e5, -300],
    [0, -110],
    [-150, -190],
    [-150, -480],
    [-80, -650],
  ])
  createCircle(system, 970, 320, 90)
  // Center buildings
  createPolygon(system, 390, 430, [
    [0, 0],
    [130, 80],
    [130, 190],
    [-10, 550],
    [-200, 550],
    [-200, 250],
  ])
}

function farmtownDistrictNack(system) {
  //createTree(system, 600, 520)
  createFirTree(system, 795, 780)
  createFirTree(system, 472, 730)
  createFirTree(system, 435, 830)
  createFirTree(system, 760, 50)

  //bicycle and trees
  createFirTree(system, 820, 505)
  createFirTree(system, 880, 555)
  createFirTree(system, 910, 555)
  createCircle(system, 760, 640, 10)

  // Shed
  createCircle(system, 250, 500, 30)
  createPolygon(system, 0, 1000, [
    [-1e5, 0],
    [0, -630],
    [410, -400],
    [420, 1000],
  ])

  //center tree and bench
  createPolygon(system, 570, 450, [
    [0, 0],
    [-10, 10],
    [20, 60],
    [35, 50],
  ])
  createCircle(system, 595, 300, 50)
  createCircle(system, 595, 350, 50)
  createCircle(system, 630, 535, 10)

  //left cow field
  createCircle(system, 282, 373, 10)
  createCircle(system, 276, 400, 10)
  createCircle(system, 132, 275, 10)

  createPolygon(system, 90, 260, [
    [0, -300],
    [-100, -100],
    [-50, 20],
    [0, 0],
  ])

  createPolygon(system, 0, 0, [
    [-1e5, -1e5],
    [450, 0],
    [290, 300],
    [0, 120],
  ])
  //heystack
  createPolygon(system, 480, 320, [
    [-50, -400],
    [-150, -100],
    [-50, -75],
    [50, -400],
  ])

  //shop
  createCircle(system, 522, 125, 10)
  createCircle(system, 692, 175, 10)
  createPolygon(system, 500, -60, [
    [-50, 0],
    [210, 165],
    [270, 0],
  ])

  //house
  createCircle(system, 730, 285, 10)
  createCircle(system, 720, 305, 10)
  createPolygon(system, 1000, -160, [
    [-50, 0],
    [-280, 500],
    [270, 800],
  ])
}

function parktownDistrictNack(system) {
  createSmallTriangle(system, 285, 305)
  //playground bench
  createPolygon(system, 235, 480, [
    [0, 0],
    [40, 25],
    [35, 30],
    [-5, 5],
  ])
  //playground
  createCircle(system, 170, 690, 10)

  createPolygon(system, 120, 550, [
    [0, 5],
    [60, -23],
    [65, -18],
    [5, 10],
  ])

  createFirTree(system, 232, 680)

  //parasols
  createPolygon(system, 140, 300, [
    [0, 0],
    [40, 25],
    [10, 80],
    [-25, 50],
  ])

  createPolygon(system, 298, 180, [
    [0, 0],
    [28, 50],
    [-5, 80],
    [-35, 40],
  ])

  //sausage atrium on the top left
  createPolygon(system, 98, 0, [
    [0, -1000],
    [60, 10],
    [28, 50],
    [-5, 80],
    [-800, 400],
  ])

  //trees top leftish
  createPolygon(system, 250, 0, [
    [0, -1000],
    [240, -1000],
    [240, 50],
    [-70, 100],
  ])

  createPolygon(system, 230, 50, [
    [0, 0],
    [20, 50],
    [10, 170],
    [-35, 170],
  ])

  //trees on the left
  createPolygon(system, -10, 170, [
    [0, 0],
    [73, 25],
    [130, 300],
    [50, 300],
    [-1000, 100],
  ])

  createCircle(system, 15, 590, 60)
  createCircle(system, 30, 940, 70)

  //playground tree
  createCircle(system, 305, 520, 30)
  createCircle(system, 305, 565, 35)
  //center tree
  createCircle(system, 400, 330, 30)
  createCircle(system, 400, 360, 45)
  createCircle(system, 400, 390, 40)

  //house bottom left, bicycle

  createPolygon(system, 162, 868, [
    [0, 0],
    [145, 85],
    [0, 500],
    [-220, 500],
  ])

  createSmallTriangle(system, 290, 860)
  createSmallTriangle(system, 330, 890)
  createSmallTriangle(system, 360, 910)
  createSmallTriangle(system, 410, 930)
  createSmallTriangle(system, 520, 880)

  //lamps
  createSmallLamp(system, 290, 720)
  createSmallLamp(system, 340, 430)
  createSmallLamp(system, 440, 110)
  createSmallLamp(system, 580, 320)
  createSmallLamp(system, 720, 270)
  createSmallLamp(system, 825, 300)
  createSmallLamp(system, 910, -10)

  //fir trees in the center
  createPolygon(system, 440, 430, [
    [0, 0],
    [82, -18],
    [145, 250],
    [-30, 370],
    [-50, 170],
  ])

  //fountain
  createCircle(system, 635, 165, 30)

  //everything on the right side
  createFirTree(system, 680, 870)
  createSmallTriangle(system, 685, 815)

  // train
  createPolygon(system, 800, 600, [
    [0, 0],
    [120, -300],
    [1000, -300],
    [1000, 0],
  ])
  // trees on the left of the train
  createPolygon(system, 680, 600, [
    [0, 0],
    [80, -200],
    [160, 0],
    [80, 100],
  ])

  createPolygon(system, 705, 1000, [
    [0, 0],
    [210, -480],
    [1000, -480],
    [3000, 3000],
  ])

  createSmallTriangle(system, 755, 790)

  createPolygon(system, 710, 720, [
    [0, 0],
    [40, 40],
    [35, 45],
    [-5, 5],
  ])

  //top right
  createFirTree(system, 875, 50)
  createFirTree(system, 820, -50)
  createCircle(system, 947, 110, 10)
}

function challengeDistrictNack(system) {
  createPolygon(system, 0, 0, [
    [-500, -500],
    [1500, -500],
    [1500, 700],
    [-500, 700],
  ])
}
