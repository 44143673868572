import { useState } from "react"

import { hero } from "./assets.js"

export function useRandomHeroes(classmates, createPolygon, district) {
  const isChallenge = district.district === "challenge"
  return useState(() =>
    classmates.map((u, id) => {
      const assetGroup = (hero[u.sex] || hero.female)[
        isChallenge ? "perspective" : "iso"
      ]
      const h = {
        id,
        assetGroup,
        items: u.equippedItems,
        x: Math.random() * 0.6 + 0.2,
        y: Math.random() * 0.6 + 0.3,
        heroName: u.heroName,
      }
      h.polygon = createPolygon()
      h.polygon.x = h.x * 1000
      h.polygon.y = h.y * 1000
      h.polygon.isOtherHero = true
      return h
    }),
  )
}
