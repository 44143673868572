import { Helmet } from "react-helmet-async"

import { CityName } from "./CityName.js"
import { FloatingLink } from "./floatingLink.js"
import { Pannable } from "./pannable.js"
import { generatePath, routes } from "./routes.js"
import { SealedFloatingLink } from "./sealedFloatingLink.js"
import { useWorldState } from "./world.js"

//TODO: this image exists as webp, but webp gives a parser error
import overviewImage from "./img/City_Overview_56.jpg"

function DistrictLink({ district }) {
  let url = generatePath(routes.district, district)
  let exercise
  if (
    district.district === "swissmoneyweek" &&
    (exercise = district.exercises[0])
  ) {
    url += `/exercise/${exercise.id}`
  }

  return (
    <FloatingLink
      url={url}
      modifierClass={`is-district to-${district.district}`}
      {...district}
    />
  )
}

export function City() {
  const { cityName, districtsBySlug, user } = useWorldState()

  return (
    <>
      <Helmet>
        <title>FinanceMission World</title>
      </Helmet>
      <Pannable identifier="city" image={overviewImage}>
        {() => (
          <>
            {Object.values(districtsBySlug).map((district) =>
              district.district === "challenge" && !user.enableChallenge ? (
                <SealedFloatingLink
                  key={district.slug}
                  modifierClass={`is-district to-${district.district}`}
                  {...district}
                />
              ) : (
                <DistrictLink district={district} key={district.slug} />
              ),
            )}
            <CityName name={cityName} />
          </>
        )}
      </Pannable>
    </>
  )
}
